<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event"/>
      <v-card-title class="headline">Reports for {{event.name}}</v-card-title>

      <v-alert v-if="event.hide_user_data" type="info" tile prominent>
        <h3>Access to user data is limited</h3>
        <p>Only users with explicitly assigned access to user (fitness) data are able to download reports for this event.</p>
        <p>When you are not able to download reports you might not have enough permissions. Please reach out to the main admin of this event for details.</p>
      </v-alert>

      <v-card-text>      
        <p>
          Download Excel reports. These reports can be used for mail-merging and keeping an eye on the progress of your event. 
        </p>

      </v-card-text>

      <v-alert v-if="!eventUtil.isProPlan()" type="info" tile>
        <div class="d-flex flex-row align-content-center">
          <p class="grow mb-0"><router-link class="white--text" :to="{name: 'eventmanagerLicense', params: {id:event.id}}">Upgrade to a PRO plan</router-link> to export Excel reports.</p>
          <v-btn class="shrink white--text" :to="{name: 'eventmanagerLicense', params: {id:event.id}}">Upgrade</v-btn>
        </div>
      </v-alert>


      <!-- <v-card-title class="subheader">Event Insights</v-card-title>
        <v-row v-if="stats">
          <v-col cols="6">
            <v-subheader>Joined Users over time</v-subheader>
            <v-sparkline
              color="primary"
              :value="Object.values(stats.participants_by_date)"
              smooth
              line-width="2"
              />
          </v-col>
        </v-row> -->
      <v-card-title class="subheader">Leaderboard Reports</v-card-title>

      <v-data-table
        :headers="headers"
        :items="races"
        :items-per-page="100"
        hide-default-footer
      >
        <template v-slot:item.count="{ item }">
          {{ getParticipantCountForRace(item.id) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn small color="primary" text class="" :disabled="!reportsAvailable" @click="downloadRaceReport(item.id, 'progress', `${item.id}-progress`)">
            <v-icon small class="mr-2">fa-download</v-icon>
            Results
          </v-btn>
          <v-btn v-if="item.team == 'RELAY_TIME_SLOTS' || item.team == 'RELAY_DISTANCE_SLOTS'" small color="primary" text class="" :disabled="!reportsAvailable" @click="downloadRaceReport(item.id, 'relay-slots', `relay slot report for ${item.id}`)">
            Relay Slots
          </v-btn>
        </template>
        <template v-slot:no-data>
          <i>No leaderboards created, add at least one leaderboard to this event.</i>
        </template>
      </v-data-table>

      <v-card-title class="subheader">Event Retention and Engagement</v-card-title>
      <v-row class="mx-2"> 
        <v-col cols="12" sm="4">     
          <v-card elevation="2">
            <v-card-title>Registrations</v-card-title>
            <v-card-text>Download this report to find a full list of registrations including their invite and join status information.</v-card-text>
            <v-card-actions>
              <v-btn color="primary" text :disabled="!reportsAvailable" @click="downloadReport('registrations', `${event.name}-registrations`)">Download</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">     
          <v-card elevation="2">
            <v-card-title>Unconnected registrations</v-card-title>
            <v-card-text>Download this report to find participants who haven't connected yet in order to get them to join as well. This will help you keep happy registrants and retain them towards future events.</v-card-text>
            <v-card-actions>
              <v-btn color="primary" text :disabled="!reportsAvailable" @click="downloadReport('unconnected-registrations', `${event.name}-registrations-unconnected`)">Download</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">     
          <v-card elevation="2">
            <v-card-title>Participants</v-card-title>
            <v-card-text>Download this report to find participants who have joined this event including their details.</v-card-text>
            <v-card-actions>
              <v-btn color="primary" text :disabled="!reportsAvailable" @click="downloadReport('participants', `${event.name}-participants`)">Download</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">     
          <v-card elevation="2">
            <v-card-title>Team Assignment</v-card-title>
            <v-card-text>Download this report to find participants who have joined this event and their team.</v-card-text>
            <v-card-actions>
              <v-btn color="primary" text :disabled="!reportsAvailable" @click="downloadReport('teamassignment', `${event.name}-teamassignment`)">Download</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">     
          <v-card elevation="2">
            <v-card-title>Participants without result</v-card-title>
            <v-card-text>Download this report to find participants who have joined, but not yet created a result. This could indicate they have troubles uploading their results. This will help you keep happy registrants and retain them towards future events.</v-card-text>
            <v-card-actions>
              <v-btn color="primary" text :disabled="!reportsAvailable" @click="downloadReport('participants-without-result', `${event.name}-participants-without-result `)">Download</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">     
          <v-card elevation="2">
            <v-card-title>Activity Breakdown</v-card-title>
            <v-card-text>Download this report to download a detailed breakdown of all activities connected with this event for further processing.</v-card-text>
            <v-card-actions>
              <v-btn color="primary" text :disabled="!reportsAvailable" @click="downloadReport('participant-activities', `${event.name}-activities`)">Download</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col v-if="false" cols="12" sm="4">     
          <v-card elevation="2">
            <v-card-title>Fairness Analysis</v-card-title>
            <v-card-text>Download this report to download a detailed breakdown of all activities connected with this event for further processing.</v-card-text>
            <v-card-actions>
              <v-btn color="primary" text :disabled="!reportsAvailable" @click="downloadReport('fairness-data', `${event.name}-activities`)">Download</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">     
          <v-card elevation="2">
            <v-card-title>Feed</v-card-title>
            <v-card-text>Download this report to download a list of all feed items and interactions.</v-card-text>
            <v-card-actions>
              <v-btn color="primary" text :disabled="!reportsAvailable" @click="downloadReport('feed', `${event.name}-feed`)">Download</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4">     
          <v-card elevation="2">
            <v-card-title>Friend Referrals</v-card-title>
            <v-card-text>Download this report to download a list of all friend referrals.</v-card-text>
            <v-card-actions>
              <v-btn color="primary" text :disabled="!reportsAvailable" @click="downloadReport('referrals', `${event.name}-referrals`)">Download</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import Header from './_Header.vue'
import EventUtil from "@/util/eventUtil";

export default {
  name: "Reports",
  components: {
    Header,
  },
  props: {
  },
  data() {
    return {
      event: null,
      provider: null,
      model: {},
      stats: null,
      siteData: siteData,
      races: [],
      headers: [
        { text: 'Name', sortable: true, value: 'name',},
        { text: 'Participants', sortable: true, value: 'count',},
        { text: 'Actions', value: 'actions', sortable: false },
      ],

    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getEvent(id) {
      this.event = (await eventManagerService.get(id)).data;
      this.races = this.event.races;
      this.stats = (await eventManagerService.getStats(id)).data;
      console.log('STATS', this.stats);
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

    getParticipantCountForRace(raceId) {
      return this.stats && this.stats.participants_by_race && this.stats.participants_by_race[raceId];
    },

    async downloadRaceReport(raceId, reportName, filename) {
      try{
        const response = await eventManagerService.exportRaceReport(this.event.id, raceId, reportName);
        this.downloadReportData(response, filename);
      }
      catch {
        this.$helpers.toastResponse(this, { status: "ERROR", msg: "No permissions to view report."});
      }
    },

    async downloadReport(reportName, filename) {
      try{
        const response = await eventManagerService.exportReport(this.event.id, reportName);
        this.downloadReportData(response, filename);
      }
      catch {
        this.$helpers.toastResponse(this, { status: "ERROR", msg: "No permissions to view report."});
      }
    },
    async downloadReportData(response, filename) {
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = filename.replace(".", "_")
        link.click()
        URL.revokeObjectURL(link.href)
    },

  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: `Reports`, disabled: true },
      ];
    },
    eventUtil() {
      return new EventUtil(this, this.event);
    },
    reportsAvailable() {
      return this.eventUtil.isProPlan();
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

